import React, { useState, useEffect, startTransition } from 'react';
import { Button, ButtonProps, Dialog } from '@mui/material';
import { useAtom } from 'jotai';
import { isMobileAtom, selectedLanguageAtom } from '@atoms/appSettings';
import { CustomSection } from '@sections/CustomSection';
import { FormParams } from '@models/form';
import { CloseIcon } from '@icon/CloseIcon';
import { v2Colors, breakpoints, DefaultV2ButtonProps, V2Button, IconButton } from '@web-for-marketing/react-ui';
import { visuallyHidden } from '@mui/utils';
import { ClassNames } from '@emotion/react';
import SiteWideFormBackground from '../../images/SiteWideDemoForm/EN.jpg';
import SiteWideFormBackgroundDE from '../../images/SiteWideDemoForm/DE-DE.jpg';
import SiteWideFormBackgroundES from '../../images/SiteWideDemoForm/ES-ES.jpg';
import SiteWideFormBackgroundESLATAM from '../../images/SiteWideDemoForm/ES-LATAM.jpg';
import SiteWideFormBackgroundFR from '../../images/SiteWideDemoForm/FR-FR.jpg';
import SiteWideFormBackgroundIT from '../../images/SiteWideDemoForm/IT-IT.jpg';
import SiteWideFormBackgroundNL from '../../images/SiteWideDemoForm/NL-NL.jpg';
import SiteWideFormBackgroundPTBR from '../../images/SiteWideDemoForm/PT-BR.jpg';
import { LanguageCode } from '@models/languageCode';

const siteWideFormBackgroundImage: Record<LanguageCode, string> = {
    [LanguageCode.en]: SiteWideFormBackground,
    [LanguageCode.enGb]: SiteWideFormBackground,
    [LanguageCode.enAu]: SiteWideFormBackground,
    [LanguageCode.apac]: SiteWideFormBackground,
    [LanguageCode.ie]: SiteWideFormBackground,
    [LanguageCode.frCa]: SiteWideFormBackgroundFR,
    [LanguageCode.frFr]: SiteWideFormBackgroundFR,
    [LanguageCode.es]: SiteWideFormBackgroundES,
    [LanguageCode.esMx]: SiteWideFormBackgroundESLATAM,
    [LanguageCode.de]: SiteWideFormBackgroundDE,
    [LanguageCode.it]: SiteWideFormBackgroundIT,
    [LanguageCode.ptBr]: SiteWideFormBackgroundPTBR,
    [LanguageCode.pl]: SiteWideFormBackground,
    [LanguageCode.nl]: SiteWideFormBackgroundNL,
    [LanguageCode.se]: SiteWideFormBackground,
    [LanguageCode.ae]: SiteWideFormBackground,
    [LanguageCode.enCb]: SiteWideFormBackground,
    [LanguageCode.idn]: SiteWideFormBackground,
};

const classes = {
    fixedPopout: {
        boxShadow: 'none',
        backgroundColor: v2Colors.core.snow,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            bottom: 'auto',
            top: 0,
            height: '100% !important',
        },
    },
    siteWideDemoPopout: (languageCode: LanguageCode) => ({
        boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.08)',
        background: `url(${siteWideFormBackgroundImage[languageCode] || SiteWideFormBackground}) no-repeat top / cover`,
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            backgroundPosition: 'top left',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            backgroundPosition: 'center',
        },
    }),
    siteWideDemoPopoutPaper: {
        position: 'unset',
        minWidth: '599.9px',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            margin: '1.6rem',
            minWidth: 'unset',
        },
    },
    popoutContentContainer: {
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
} as const;

interface BaseProps {
    text: string;
    customSectionKey?: string;
    closeAdditional?: () => void;
    params?: FormParams;
}

interface MUIButtonProps extends ButtonProps {
    isV2?: false;
}

interface V2ButtonProps extends Omit<DefaultV2ButtonProps, 'children'> {
    isV2: true;
}

type PopoutButtonProps = BaseProps & (MUIButtonProps | V2ButtonProps);

export function PopoutButton({
    text,
    customSectionKey,
    params = [],
    closeAdditional,
    isV2,
    ...buttonProps
}: PopoutButtonProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [open, setOpen] = useState(false);
    const [selectedLanguage] = useAtom(selectedLanguageAtom);

    const setPopoutOpen = (popoutValue: boolean): void => {
        if (closeAdditional) closeAdditional();
        startTransition(() => {
            setOpen(popoutValue);
        });
    };

    const handleClose = (): void => {
        setPopoutOpen(false);
    };

    useEffect(() => {
        if (open) {
            setPopoutOpen(false);
        }
        return () => {
            if (open) {
                setPopoutOpen(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return (
        <>
            {isV2 ? (
                <V2Button
                    aria-label={`Open ${text} popout`}
                    onClick={() => setPopoutOpen(true)}
                    {...(buttonProps as DefaultV2ButtonProps)}
                >
                    {text}
                </V2Button>
            ) : (
                <Button
                    aria-label={`Open ${text} popout`}
                    onClick={() => setPopoutOpen(true)}
                    {...(buttonProps as ButtonProps)}
                >
                    {text}
                </Button>
            )}
            {open ? (
                customSectionKey === 'MultistepDemoForm' ? (
                    <ClassNames>
                        {({ css }) => (
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                css={classes.siteWideDemoPopout(selectedLanguage)}
                                classes={{ paper: css(classes.siteWideDemoPopoutPaper) }}
                                aria-labelledby='popout-button-title'
                            >
                                <h2 style={visuallyHidden} id='popout-button-title'>
                                    {text}
                                </h2>
                                <CustomSection
                                    component={customSectionKey}
                                    params={params}
                                    lazyLoad={false}
                                    displayMode='bg-popout'
                                    onClose={handleClose}
                                />
                            </Dialog>
                        )}
                    </ClassNames>
                ) : (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        hideBackdrop
                        fullScreen
                        css={classes.fixedPopout}
                        aria-labelledby='popout-button-title'
                    >
                        <h2 style={visuallyHidden} id='popout-button-title'>
                            {text}
                        </h2>
                        <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                label='Close dialog'
                                variant='secondary'
                                size='small'
                                shape='round'
                                css={{ margin: '1.5rem' }}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div css={classes.popoutContentContainer}>
                            <CustomSection
                                component={customSectionKey}
                                params={params}
                                lazyLoad={false}
                                displayMode='full-popout'
                                onClose={handleClose}
                            />
                        </div>
                    </Dialog>
                )
            ) : null}
        </>
    );
}
